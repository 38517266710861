<script lang="ts" setup>
import type { Game } from "@/types";

type GameItem = {
	slug: string;
	type: string;
	type_component: string;
	row_count: number;
};

const props = defineProps<{
	itemSection: number;
	hideOnLeave?: boolean;
}>();

const { pageData, yourPick } = useHomePage();
const isGuest = useIsGuest();
const { select } = useGamesState();
const { handleToggleToFavoriteClick } = useAddToFavorite();
const { isMobile } = useDevice();

const games = computed(() => pageData.value?.schemeSections?.[props.itemSection] || []);

const isRecentlyPlayedSection = (game: GameItem) => game?.slug === "last" && !isGuest.value && yourPick.value?.length;

const emit = defineEmits<{ (event: "update"): void }>();

const handleFavoriteClick = (game: Game) => {
	handleToggleToFavoriteClick(game || {});
	emit("update");
};

const getDataTid = (index?: number) => {
	if (index === 0) {
		return `slider-first-item`;
	}
	return null;
};

const gameSectionHeight = {
	slider_horizontal: 235,
	"weekly-top-10": isMobile ? 200 : 286
};

const skeletonHeight = ({ type, rows = 1 }: { type?: keyof typeof gameSectionHeight; rows?: number }) => {
	const headerHeight = 46; // default header height of section
	const defaultGridCardHeight = isMobile ? 200 : 286;
	const gapSize = 8;
	const gap = rows > 1 ? (rows - 1) * gapSize : 0;

	return `${(type ? gameSectionHeight[type] : defaultGridCardHeight) * rows + gap + headerHeight}px`;
};
</script>
<template>
	<template v-for="(game, index) in games" :key="index">
		<OLazyComponent v-if="game && isRecentlyPlayedSection(game)" id="yourPickGames" :height="368">
			<MHomeSectionWrapper v-if="yourPick?.length > 0" title="your picks" route="/issues/favorites/">
				<MGameType
					v-for="(item, num) in yourPick"
					:key="num"
					:game="item"
					:data-tid="getDataTid(num)"
					@toggle-favorite="handleFavoriteClick"
				/>
			</MHomeSectionWrapper>
		</OLazyComponent>
		<OLazyComponentNew
			v-else-if="
				pageData?.games[camelCase(game?.slug ?? '')]?.length && pageData?.games[camelCase(game?.slug ?? '')]?.length > 0
			"
			:id="`home-game-${game?.slug}`"
			:height="skeletonHeight({ type: game?.type_component, rows: !game?.row_count ? 1 : game?.row_count })"
			:hideOnLeave="hideOnLeave"
		>
			<MHomeSectionWrapper
				:type="game?.type_component"
				:row="game?.row_count ?? 1"
				:title="pageData?.gamesInfo[camelCase(game?.slug ?? '')]?.title || ''"
				:route="`/issues/${game?.slug}/`"
			>
				<MGameType
					v-for="(item, num) in select(pageData?.games[camelCase(game?.slug ?? '')])"
					:key="num"
					:game="item"
					:category="game?.slug"
					:type="game?.type_component"
					:index-game="num"
					:data-tid="getDataTid(num)"
					@toggle-favorite="handleFavoriteClick"
				/>
			</MHomeSectionWrapper>
		</OLazyComponentNew>
	</template>
</template>

<style />
